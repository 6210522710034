.full-height-grid{
  height: 100vh;
}

.full-height-grid.with-nav{
  width: 100%;
  position: absolute;
  top: -24px;
}

.full-height{
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

.scrollable-y{
  overflow-y: auto;
  overflow-x: hidden;
}

.float-right{
  float: right;
  margin-left: 5;
}
.float-left{
  float: left;
}

.full-width{
  width: 100%;
}

.transportador-label{
  width: 246px;
  text-align: left;
}

.orcamentos-status-label{
  width: 88px;
}

.visualizado-label{
  width: 108px
}

.confirm-form-container{
  background-color: #ccc
}

.email-label{
  width: 245px;
}

.cpf-label{
  width: 140px
}

.autorizado-label{
  width: 95px;
  text-align: center;
}

.ajudante-label{
  width: 114px;
  text-align: center;
}

.app-message{
  margin-top: 30%;
  left: 0;
  position: 'fixed';
  top: 0;
  zIndex: 1000;
  width: 100%
}

.zero-padding{
  padding: 0;
}

tr.red-row td{background-color: #D95C5C}
tr:hover.red-row td{background-color: #333 !important}

.green-row{background-color: #5BBD72}
tr:hover.green-row td{background-color: #333 !important}

.blue-row{background-color: #3B83C0}
tr:hover.blue-row td{background-color: #333 !important}

.teal-row{background-color: #00B5AD}
tr:hover.teal-row td{background-color: #333 !important}

.orange-row{background-color: #F2711C}
tr:hover.orange-row td{background-color: #333 !important}

.bg-white{background-color: #FFFFFF}

.bouncer{
  animation-name: bounceIn;
  animation-duration: 400ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.2);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}

.item-right-rail{
  width: 200px;
  float: right;
  margin: 18px;
}

.ui.sidebar {
    overflow: visible !important;
}

.top-bottom-border {
  border-top: rgba(34, 36, 38, 0.15) 1px solid;
  border-bottom: rgba(34, 36, 38, 0.15) 1px solid;
}

.draggable-helper{
  z-index: 10000;
}

.draggable-item{
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  z-index: 10000!important;
  padding:0 0 7px !important;
}

.selectable-row td{
  cursor: pointer;
} 

.dashboard-container{
  height: 94vh;
  display:flex;
  flex-flow: column;
}

.card-orcamento{

  height: 10vh;
  display:flex;
  flex-flow: column;
}

.dashboard-map{
  min-height: 500px;
}

@media screen and (max-width: 800px) {
  .dashboard-container{height:1500px}
}

.inputCls {
  font-weight: bold;
  color: red;
} 

.button-list {
  margin-top: 18px;
} 

.button-transporter-fee {
  background-color: #00B5AD;
  margin-left: 50px;
  align-items: center;
  justify-content: center;
} 

.rowC {

  align-items: center;
  justify-content: flex-start;
  display:flex; 
  flex-direction:row;
}